.Top {
    width: 100vw;
    background-color: rgb(36, 35, 35);
    height: 5vh;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
}

.Top a {
    text-decoration: none;
    color: white;
    
}

.Top ul {
    display: flex;
    padding: 10px;
    align-items: center;
}

.Top li {
    list-style: none;
    padding: 10px;
    font-size: 1.5rem;
}