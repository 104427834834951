.Main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-color: white;
    width: fit-content;
    margin: auto;   
    margin-top: 2vh;
    border: 20px ridge #761012;
    border-radius: 50px;
}

.Main a{
    text-decoration: none;
    color: #000;
}

.Main img {
    width: 80vw;
    max-width: 500px;
    object-fit: cover;
}


.Main h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3rem;
}